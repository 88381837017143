import Componenets from './components/components';
import Modules from './modules/modules';
import polyfill from './_config/js/polyfill';
import {
    isInViewport,
    addThisInViewport
} from './utilities/viewport/viewport';

const componets = new Componenets;
const modules = new Modules;

$.fn.isInViewport = isInViewport;


$(function () {
    polyfill();
    componets.onDocumentReady();
    modules.onDocumentReady();
});

$(window).on('scroll', componets.onWindowScroll);
$(window).on('resize scroll', function () {
    if ($('footer').isInViewport()) {
        addThisInViewport();
    }
});
componets.onDocumentClick();

import FeatureWidget from './FeatureWidget/FeatureWidget';

$(function () {
    // zakomentirati da se isključi feature widget
    // const featureWidget = new FeatureWidget();
    // featureWidget.init();

    $('.no-subpage .blog-link').on('click', function (e) {
        e.preventDefault();
        $($(this).attr('data-target')).modal('show');
    })
})