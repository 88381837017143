class HeaderSlider {
    constructor(prevArw, nextArw) {
        this.settings = {
            slidesToShow: 1,
            dots: false,
            infinite: true,
            speed: 800,
            fade: true,
            prevArrow: prevArw,
            nextArrow: nextArw,
            autoplay: true,
        };

        this.galleries_settings = {
            slidesToShow: 1,
            dots: false,
            infinite: true,
            speed: 800,
            fade: true,
            prevArrow: prevArw,
            nextArrow: nextArw,
            autoplay: true,
        };
    }

    init() {
        if ($('body').hasClass('galleries_post')) {
            // $('.header-slider').slick(this.galleries_settings);

            var $slider = $('.header-slider');

            if ($slider.length) {
                var currentSlide;
                var slidesCount;
                var sliderCounter = document.createElement('div');
                sliderCounter.classList.add('slider__counter');

                var updateSliderCounter = function (slick, currentIndex) {
                    currentSlide = slick.slickCurrentSlide() + 1;
                    slidesCount = slick.slideCount;
                    $(sliderCounter).text(currentSlide + '/' + slidesCount)
                };

                $slider.on('init', function (event, slick) {
                    $slider.append(sliderCounter);
                    updateSliderCounter(slick);
                });

                $slider.on('afterChange', function (event, slick, currentSlide) {
                    updateSliderCounter(slick, currentSlide);
                });

                $slider.slick(this.galleries_settings);
            }
        } else {
            $('.header-slider').slick(this.settings);
        }
    }

    refresh() {
        if ($('.header-slider').length) {
            $('.header-slider').slick('refresh');
        }
    }
}

export default HeaderSlider;